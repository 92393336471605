import { IncomingMessage } from 'http';
import { Context } from '@nuxt/types';

/**
 * This function checks if the given href leads to an internal or external page which means
 * - the URL starts with http or https and the domain does not match the current domain
 * - the URL starts with tel
 */
export function isExternal(href: string, ssrContext: Context): boolean {
	const domain = getDomainName(ssrContext?.req);
	const isExternalPage = new RegExp(`^https?://(?!${domain})[^/]*(/.*)?$`).test(href);
	const isPhone = href.startsWith('tel:');
	const isInPageLink = href.startsWith('#');

	return isExternalPage || isPhone || isInPageLink;
}

export function getDomainName(req: IncomingMessage): string {
	return (req?.headers?.host || window?.location?.host)?.toLowerCase();
}
