var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isExternal)?_c('a',_vm._b({attrs:{"data-name":"link","data-external":!_vm.link.href.startsWith('#') && !_vm.link.href.startsWith('/')},on:{"click":_vm.click}},'a',{
		href: _vm.link.href,
		target: _vm.link.target,
		rel: _vm.link.rel,
		'data-id': _vm.link.uid,
	},false),[_vm._t("default")],2):_c('RouterLink',_vm._b({attrs:{"data-name":"link"},nativeOn:{"click":function($event){return _vm.click.apply(null, arguments)}}},'RouterLink',{
		to: _vm.internalHref,
		target: _vm.link.target,
		rel: _vm.link.rel,
		'data-id': _vm.link.uid,
	},false),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }