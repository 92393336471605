import { PropType } from 'vue';
import { Props } from '../../types/core';
import { FooterSection } from '../../types/footer';

// Model -------------------------------------------------------------------------------------------

export interface FooterNavigationModel {
	uid: string;
	items: FooterSection[];
	defaultActive?: number;
}

// Props -------------------------------------------------------------------------------------------

export const FooterNavigationProps: Props<FooterNavigationModel> = {
	uid: {
		type: String as PropType<FooterNavigationModel['uid']>,
		required: true,
	},
	items: {
		type: Array as PropType<FooterNavigationModel['items']>,
		required: true,
	},
	defaultActive: {
		type: Number as PropType<FooterNavigationModel['defaultActive']>,
		default: null,
	},
};
