import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Link } from '../../types/link';
import { CLSModel, CLSProps } from '../../types/clsModel';

// Model -------------------------------------------------------------------------------------------

export interface LinkModel extends CLSModel {
	link: Omit<Link, 'text'>;
}

// Props -------------------------------------------------------------------------------------------

export const LinkProps: Props<LinkModel> = {
	...CLSProps,
	link: {
		type: Object as PropType<LinkModel['link']>,
		required: true,
	},
};
