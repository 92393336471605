import Vue from 'vue';
import { FooterNavigationProps } from './FooterNavigation.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'FooterNavigation',
	props: FooterNavigationProps,
	data() {
		return {
			current: this.defaultActive,
		};
	},
	computed: {
		links(): any {
			return this.items.map((item, index) => ({ ...item, isActive: index === this.current }));
		},
	},
	methods: {
		handleHeadingClick(index: number) {
			this.current = this.current === index ? undefined : index;
		},
	},
});
