import Vue from 'vue';
import { getDomainName, isExternal } from '../../assets/js/utilities/links';
import { ClsContainerMixin } from '../mixins/cls-container-mixin';
import { ClsComponentMixin } from '../mixins/cls-component-mixin';
import { LinkProps } from './Link.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Link', // eslint-disable-line vue/no-reserved-component-names
	mixins: [ClsContainerMixin, ClsComponentMixin],
	props: LinkProps,
	data() {
		return {
			isExternal: false,
			internalHref: '',
		};
	},
	watch: {
		link() {
			this.updateLinkSettings();
		},
	},
	created() {
		this.updateLinkSettings();
	},

	methods: {
		updateLinkSettings() {
			this.isExternal = isExternal(this.link.href, this.$ssrContext);
			this.internalHref = this.getInternalHref();
		},
		click(event: PointerEvent) {
			this.$emit('click', event);
		},
		getInternalHref() {
			const domain = getDomainName(this.$ssrContext?.req);
			const regexp = new RegExp(`^https?://${domain}`);

			return this.link.href.replace(regexp, '');
		},
	},
});
